<template>
  <div :class="[$style.page, {[$style.pageForceFullWidth]: forceFullWidth}]">
    <component :is="hasWhiteCard && !isNested ? 'aw-spring' : 'div'">
      <component
        :is="hasWhiteCard ? 'aw-cms-white-card' : 'div'"
        mobile-negative-margin
        :class="[{[$style.whiteCard]: hasWhiteCard}]"
      >
        <template v-if="hasWhiteCard && (isPrizeGame || showPageWithMenu)" #head>
          <div :class="$style.headerWrapper">
            <lv-heading
              level="1"
              tag="h1"
              version2
            >
              {{ title }}
            </lv-heading>
            <lv-button
              v-if="showPageWithMenu === 'loyalty-program-menu'"
              :class="$style.headerButton"
              type="button"
              stretch
              styling="primary-dark"
              @click="goToLoyaltyCard"
            >
              {{
                loyaltyCard ? $awt('aw.pages.loyalty_program.go_to_loyalty_card') : $awt('aw.pages.loyalty_program.create_loyalty_card')
              }}
            </lv-button>
          </div>
        </template>
        <div :class="{[$style.petrolMenu]: showPageWithMenu}">
          <component :is="menuWrapperComponent">
            <div>
              <component :is="hasWhiteCard ? 'div' : 'aw-spring'" v-if="previewBannerContent">
                <aw-cms-preview :content="previewBannerContent" :is-simplified="isPrizeGame" />
              </component>
              <component
                :is="hasWhiteCard || isNested ? 'div' : 'aw-spring'"
                v-for="(row, rowIndex) in gridElements"
                :key="row.id"
                :desktop-size="row.fullWidth || forceFullWidth ? 100 : 10"
                :class="(row.fullWidth || forceFullWidth) && hasWhiteCard && $style.whiteCardFullWidth"
              >
                <div
                  :class="[
                    $style.rowSpacing,
                    {
                      [$style.rowSpacingTypeIsNegative]: row.spacingType === 'negative',
                      [$style[`rowSpacing${row.spacingInner}Inner`]]: row.spacingInner,
                      [$style[`rowSpacing${row.spacingBefore}Before`]]: row.spacingBefore,
                      [$style[`rowSpacing${row.spacingAfter}After`]]: row.spacingAfter,
                      [$style.mobileIsCardOverflow]: IsCardOverflow(row),},
                  ]"
                  :style="{
                    overflow: row.overflowAlwaysVisible || row.overflowVisible && isMounted && screenRange['tablet-min'] ? 'visible' : 'hidden',
                    ...rowSpacingOverride[row.id],
                    backgroundColor: rowBackgroundColor[row.id],
                    backgroundImage: rowBackgroundImage[row.id],
                  }"
                >
                  <div :class="$style.rowHeader">
                    <lv-heading
                      v-if="row.title"
                      :id="componentIds[row.items[0]?.content?.type] || null"
                      level="2"
                      :tag="headingTag"
                      version2
                      :class="[$style.componentHeader, {[$style.componentHeaderWithSideMargin]: !hasWhiteCard}]"
                    >
                      {{ row.title }}
                    </lv-heading>
                    <div v-else />
                    <div :id="`awLayoutRowHeader-${row.id}`" />
                  </div>
                  <div
                    :class="[
                      $style.container,
                      $style[row.spacingInner],
                      $style[`container-${row.layoutType}`],
                      {
                        [$style.containerBackgroundFill]: row.backgroundRepeat === 'fill',
                        [$style.containerBackgroundRepeat]: row.backgroundRepeat === 'repeat',
                        [$style.containerBackgroundFixed]: row.backgroundRepeat === 'fix',
                        [$style.containerContainsOnlyCmsCard]: isAllElementIsCMSCard(row),
                        [$style.containerContainsOnlyCatalog]: isAllElementIsCatalog(row),
                        [$style.containerFullTiles]: row.layoutType === 'full_tiles' && !row.fullWidth,
                        [$style.containerWithoutTitle]:!row.title,
                        [$style[`gapSpacing${row.spacingInner}`]]: row.spacingInner,
                      },
                    ]"
                  >
                    <div
                      v-for="(gridElement, gridElementIndex) in row.items"
                      :key="`${gridElement.id}-${gridElementIndex}`"
                      :class="[
                        $style.item,
                        $style[`item-${gridElement.widthType}`],
                        $style[`item-${gridElement.widthType}-${gridElement.index}`],
                        {
                          [$style.itemHasLoadingError]: hasLoadingError(gridElement.id),
                          [$style.itemOverflowVisibleFromTablet]: gridElement.content.type === 'last_viewed_products' || gridElement.content.layoutTemplate === 'full_carousel_cms_card' || gridElement.content.type === 'recommendation_carousel',
                          [$style.itemOverflowVisibleAlways]: ['full_flow_chart_cards','full_flow_chart_with_image_cards','full_what_auchan_provide', 'full_video_cards', SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE, SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE].includes(gridElement.content.layoutTemplate)
                        }
                      ]"
                    >
                      <aw-if-visible :root-margin="(w) => `${w.innerHeight}px 0px`" :class="$style.visibilityWrapper">
                        <component
                          :is="ContentPageTypeConfig[gridElement.content.type]?.component"
                          :key="gridElementKey(gridElement)"
                          :has-sidebar="!!showPageWithMenu"
                          :content-id="gridElement.content.contentId"
                          :row-id="row.id"
                          :content="gridElement.content"
                          :heading-tag="row.title ? childrenHeadingTag : headingTag"
                          :has-section-title="!!row.title"
                          :is-on-white-card="hasWhiteCard"
                          :analytics-banner-interaction="AnalyticsBannerInteraction.create({
                            promotionName: null,
                            promotionComponentId: ContentPageTypeConfig[gridElement.content.type]?.analytics,
                            promotionIndex: calculateIndex(rowIndex, gridElementIndex),
                            promotionBannerIndex: 1,
                            promotionCompanyNo: contentData.contentCompanyNo || null,
                            promotionPageHref: (contentData.awCmsContentHref || $getHref()).replace(/^(https*:\/\/|\/\/)/, '').replace($getHost(), ''),
                          })"
                          :analytics-measurable="gridElement.content.measurablePromotion"
                          v-bind="componentAttr[row.id][gridElementIndex]"
                          @loading-error="setLoadingError(gridElement.id, $event)"
                        />
                      </aw-if-visible>
                    </div>
                  </div>
                </div>
              </component>
            </div>
          </component>
        </div>
      </component>
    </component>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { defineAsyncComponent, markRaw } from 'vue';
  import AnaliticsClasses from '~~/common/config/AnaliticsClasses';
  import headingTagMixin from '~~/common/mixins/headingTagMixin.js';
  import ContentPageTypeConfig, {
    HT_STANDARD,
    HT_CAROUSEL,
    HT_CATALOG,
    HT_PRODUCT_REVIEW,
  } from '~~/common/config/ContentPageTypeConfig';
  import { Navigation, ID_PRODUCT_REVIEWS } from '~~/common/config/Navigation.js';
  import { colorModelToHex } from '~~/common/utils';
  import { createGridElementsFromContentData } from '~~/common/utils/contentData.js';
  import { LvButton, LvHeading } from '~~/common/components/loginet-vue-shop/index.mjs';
  import {
    SCT_CMS_CARD, SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE, SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE,
    SCT_IMAGE_TEXT_LINK_COLUMNS,
    SCT_LARGE_BANNER_CAROUSEL,
  } from '~~/common/config/AwStandardTypeConfig';
  import { AnalyticsBannerInteraction } from '~~/common/utils/AnalyticsBannerInteraction';
  import AwSpring from '~~/common/components/AwSpring';
  import AwCmsWhiteCard from '~~/common/components/AwCmsWhiteCard';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import AwCmsPreview from './AwCmsPreview.vue';
  import AwPetrolMenu from '~~/shop/components/Page/Petrol/AwPetrolMenu.vue';
  import AwCareerMenu from '~~/common/components/AwCareerMenu.vue';
  import AwLoyaltyProgramMenu from '~~/shop/components/Page/LoyaltyProgram/AwLoyaltyProgramMenu.vue';
  import { navigateTo } from 'nuxt/app';
  import { CMS_PAGE_MENU_WRAPPER_COMPONENTS_MAP } from '~~/shop/awPlugins/app-config.js';
  import { useLoyaltyCardStore } from '~~/shop/stores/loyaltyCard.js';
  import { getBackgroundImages } from '~~/common/utils/imageHelper.js';

  export default {
    name: 'AwCmsPage',
    components: {
      LvButton,
      AwPetrolMenu,
      AwCareerMenu,
      AwLoyaltyProgramMenu,
      AwSpring,
      AwCmsWhiteCard,
      AwIfVisible: defineAsyncComponent(() => import('~~/common/components/Common/AwIfVisible')),
      LvHeading,
      AwCmsPreview,
    },
    mixins: [
      headingTagMixin,
    ],
    provide () {
      return {
        getRowIndexById: this.getRowIndexById,
      };
    },
    props: {
      contentData: {
        type: Object,
        required: true,
      },
      forceFullWidth: {
        type: Boolean,
        default: false,
      },
      inlineCmsBreakpoints: {
        type: Array,
        default: null,
      },
      headingTag: {
        type: String,
        default: 'h2',
      },
      isNested: {
        type: Boolean,
        default: false,
      },
      startingPromotionIndex: {
        type: Number,
        default: 0,
      },
    },
    setup () {
      return {
        ContentPageTypeConfig: markRaw(ContentPageTypeConfig),
      };
    },
    data () {
      return {
        isMounted: false,
        AnaliticsClasses,
        AnalyticsBannerInteraction,
        loadingErrors: {},
        SCT_EXPANDABLE_CARD_GALLERY_TEXT_ON_IMAGE,
        SCT_EXPANDABLE_CARD_GALLERY_TEXT_UNDER_IMAGE,
      };
    },
    computed: {
      ...mapState(useLoyaltyCardStore, {
        loyaltyCard: state => state.loyaltyCard,
      }),
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      ...mapState(useDeliveryStore, {
        gridElementKey (state) {
          return gridElement => `grid-${gridElement?.content?.contentId ?? ''}-${state.setup?.id}`;
        },
      }),
      gridElements () {
        return createGridElementsFromContentData(this.contentData);
      },
      rowSpacingOverride () {
        const result = {};
        for (const row of this.gridElements) {
          if (row && typeof row === 'object') {
            result[row.id] = null;
            if (Array.isArray(row.awFeOverrideSpacing) && this.isMounted) {
              result[row.id] = Object.assign(
                result[row.id],
                ...(row.awFeOverrideSpacing
                  .filter(o => o?.css && (o.at === 'each' || this.screenRange[o.at]))
                  .map(o => o.css)
                ),
              );
            }
          }
        }
        return result;
      },
      rowBackgroundColor () {
        const result = {};
        for (const row of this.gridElements) {
          if (row && typeof row === 'object') {
            result[row.id] = (row.backgroundColor?.hex && row.backgroundColor?.alpha)
              ? colorModelToHex(row.backgroundColor)
              : 'transparent'
            ;
          }
        }
        return result;
      },
      rowBackgroundImage () {
        const result = {};
        for (const row of this.gridElements) {
          if (row && typeof row === 'object') {
            const images = [
              {
                ...row?.media?.largeBackgroundImage,
                media: row?.media?.largeBackgroundImage && row?.media?.smallBackgroundImage ? `(min-width: ${this.$styleVariables.breakpointTablet})` : undefined,
              },
              row?.media?.smallBackgroundImage,
            ];

            const backgroundImage = this.isMounted ?
              getBackgroundImages(this.screenRange['tablet-min'] ? images : images.reverse())[0]?.url ?? ''
              : ''
            ;

            result[row.id] = backgroundImage ? `url('${backgroundImage}')` : null;
          }
        }
        return result;
      },
      componentAttr () {
        /*
          NOTE: ID_IMAGE_CAR_1_COMPAT="sales-carousel-1" id may be already
          used by auchan so for safety reasons we hardcoded it for the
          first carousel. "sales-carousel-2" cannot be used here, because
          that is already *hardcoded* for AwShortlist and
          AwProductRecommendation components.
        */
        const ID_IMAGE_CAR_1_COMPAT = 'sales-carousel-1';
        let cid = 1;
        const result = {};
        for (const row of this.gridElements) {
          if (row && typeof row === 'object') {
            result[row.id] = {};
            for (const idx in row.items) {
              const currentElement = row.items[idx];
              const propBindings = result[row.id][idx] = {};
              const compDefinition = ContentPageTypeConfig[currentElement.content.type]?.component;
              if (currentElement.content.type === HT_CAROUSEL) {
                propBindings.class = `${AnaliticsClasses.carouselMainContainer}${cid}`;
                propBindings.carouselId = cid === 1 ? ID_IMAGE_CAR_1_COMPAT : `sales_carousel_image_${cid}`;
                cid++;
              } else if (currentElement.content.type === HT_STANDARD) {
                propBindings.addBackground = ![SCT_LARGE_BANNER_CAROUSEL, SCT_CMS_CARD, SCT_IMAGE_TEXT_LINK_COLUMNS].includes(currentElement.content.layoutTemplate);
              }
              if (compDefinition?.props?.inlineCmsBreakpoints) {
                propBindings.inlineCmsBreakpoints = this.inlineCmsBreakpoints;
              }
            }
          }
        }
        return result;
      },
      hasWhiteCard () {
        return this.contentData?.whiteBoxed || this.showPageWithMenu;
      },
      /* currentDevice ()
        if (this.screenRange['desktop-medium-min']) {
          return 'desktop';
        }
        if (this.screenRange['desktop-small-min']) {
          return 'desktopSmall';
        }
        if (this.screenRange['tablet-min']) {
          return 'tablet';
        }
        if (this.screenRange['mobile-max']) {
          return 'mobile';
        }
        return '';
      }, */
      previewBannerContent () {
        if (!this.contentData?.media?.carouselCardImage) {
          return null;
        }
        return {
          text: this.contentData?.lead,
          title: this.contentData?.leadTitle,
          image: this.contentData?.media?.carouselCardImage,
        };
      },
      isPrizeGame () {
        const componentList = this.contentData?.layoutBlocks?.flatMap(element => element.components);
        return componentList?.some(component => component.type === 'prize_game');
      },
      title () {
        if (this.contentData?.inMenu === 'petrol-menu') {
          return this.$awt('aw.pages.petrol.title');
        }

        if (this.contentData?.inMenu === 'career-menu') {
          return this.$awt('aw.pages.career.title');
        }
        if (this.contentData?.inMenu === 'loyalty-program-menu') {
          return this.$awt('aw.pages.loyalty_program.title');
        }

        return this.previewBannerContent?.title;
      },
      showPageWithMenu () {
        return this.contentData?.inMenu;
      },
      menuWrapperComponent () {
        if (this.showPageWithMenu) {
          return CMS_PAGE_MENU_WRAPPER_COMPONENTS_MAP?.[this.contentData.inMenu];
        } else {
          return 'div';
        }
      },
      componentIds () {
        return {
          [HT_PRODUCT_REVIEW]: Navigation[ID_PRODUCT_REVIEWS],
        };
      },
    },
    mounted () {
      this.isMounted = true;
    },
    methods: {
      calculateIndex (rowIndex, gridElementIndex) {
        let index = this.startingPromotionIndex + gridElementIndex + 1;
        this.gridElements.some((e, idx) => {
          if (idx < rowIndex) {
            index += e?.items?.length || 0;
          }
          return idx === rowIndex;
        });
        return index;
      },
      isAllElementIsCMSCard (row) {
        const componentsCount = row.components.length;
        const cmsCardCount = row.components.filter((component) => {
          return component.layoutTemplate === SCT_CMS_CARD;
        }).length;
        return componentsCount === cmsCardCount;
      },
      isAllElementIsCatalog (row) {
        const componentsCount = row.components.length;
        const catalogCount = row.components.filter((component) => {
          return component.type === HT_CATALOG;
        }).length;
        return componentsCount === catalogCount;
      },
      setLoadingError (key, data) {
        this.loadingErrors[key] = data;
      },
      hasLoadingError (key) {
        return this.loadingErrors[key] || false;
      },
      IsCardOverflow (row) {
        return row?.items.some(item => {
          return ['full_carousel_cms_card', 'article_list', 'shortlist', 'previously_ordered_products', 'last_viewed_products', 'recommendation_carousel'].includes(item?.content?.type) || ['full_carousel_cms_card'].includes(row.layoutType);
        });
      },
      goToLoyaltyCard () {
        navigateTo({
          path: this.localePath('/shop/profile/loyalty-program'),
        });
      },
      getRowIndexById (rowId) {
        return this.gridElements.findIndex(element => element.components?.some(component => component.id === rowId));
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">

.headerWrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 20px;

  @include tablet(min) {
    align-items: center;
    flex-direction: row;
    gap: 40px;
  }
}

.headerButton {
  width: max-content;
}

.none, :root {
  // stylelint-disable length-zero-no-unit
  --cms-page-calculated-spacing: 0px;
}

.small {
  --cms-page-calculated-spacing: 6px;
}

.normal {
  --cms-page-calculated-spacing: 12px;
}

.big {
  --cms-page-calculated-spacing: 18px;
}

.double {
  --cms-page-calculated-spacing: 24px;
}

[class = "40"] {
  --cms-page-calculated-spacing: 30px;
}

[class = "80"] {
  --cms-page-calculated-spacing: 60px;
}

.page {
  font-family: $secondary-font;
  color: $color-text-primary;

  @include desktop-large(min) {
    &:is(&ForceFullWidth) [data-spring-wrapper] {
      grid-template-columns: repeat(14, 1fr);
    }
  }
}

.whiteCard {
  padding-bottom: 40px;

  @include tablet(min) {
    margin-bottom: 20px;
    padding-bottom: 80px;
  }

  &FullWidth {
    width: calc(100% + 32px);
    margin-left: -16px;

    @include desktop-small(min) {
      width: calc(100% + 80px);
      margin-left: -40px;
    }
  }
}

.rowHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.container {
  display: grid;
  gap: var(--awCmsPageGapSpacing, 0);

  background-position: top center;
  object-position: top center;
  background-size: auto;

  &BackgroundFill, &BackgroundFixed {
    background-repeat: no-repeat;
    background-attachment: initial;
  }

  &BackgroundFill {
    background-size: 100% 100%;
  }

  &BackgroundRepeat {
    background-repeat: repeat;
  }

  &BackgroundFixed {
    background-position: center;
    background-size: cover;
    object-position: center;
  }

  .item {
    overflow: auto;

    &OverflowVisible {
      &Always {
        overflow: visible;
      }

      &FromTablet {
        @include tablet(min) {
          overflow: visible;
        }
      }
    }

    @include tablet(min) {
      padding-top: 40px;
    }

    @include desktop-small(min) {
      overflow: visible;
    }

    &:first-child {
      @include mobile(max) {
        padding-top: 20px;
      }
    }

    &.item-full {
      /*no op */
      width: 100%;
    }

    &.item-half {
      grid-column: span 2;
    }

    &.item-third {
      grid-column: span 1;
    }

    &.item-quarter {
      grid-column: span 1;
    }

    &.item-two_thirds {
      grid-column: span 2;
    }

    &.item-three_quarters {
      grid-column: span 3;
    }

    &HasLoadingError {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .visibilityWrapper {
      height: 100%;
    }
  }

  &.container-full {
    /*no op */
  }

  &.container-full_carousel {
    /*no op */
  }

  &.container-half\|half {
    grid-template-columns: repeat(4, 1fr);
  }

  &.container-third\|two_thirds {
    grid-template-columns: repeat(3, 1fr);
  }

  &.container-two_thirds\|third {
    grid-template-columns: repeat(3, 1fr);
  }

  &.container-quarter\|three_quarters,
  &.container-three_quarters\|quarter,
  &.container-half\|quarter\|quarter,
  &.container-quarter\|half\|quarter,
  &.container-quarter\|quarter\|half,
  &.container-quarter\|quarter\|quarter\|quarter {
    grid-template-columns: repeat(4, calc(25% - #{var(--cms-page-calculated-spacing)}));
  }

  &.container-third\|third\|third {
    grid-template-columns: repeat(3, 1fr);
  }

  &.container-quarter\|quarter\|quarter {
    grid-template-columns: repeat(8, 1fr);

    .item-quarter-0 {
      grid-column: 2 / span 2;
    }

    .item-quarter-1 {
      grid-column: 4 / span 2;
    }

    .item-quarter-2 {
      grid-column: 6 / span 2;
    }
  }

  &.container-third\|third {
    grid-template-columns:  repeat(6, 1fr);

    .item-third-0 {
      grid-column: 2 / span 2;
    }

    .item-third-1 {
      grid-column: 4 / span 2;
    }
  }

  &.container-half {
    grid-template-columns: repeat(4, 1fr);

    .item-half-0 {
      grid-column: 2 / span 2;
    }
  }

  &.container-half\|half_column_of_halves {
    grid-template-columns: repeat(2, 1fr);

    .item-half-0 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    .item-half-1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .item-half-2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }

  &.container-half\|half_column_of_thirds {
    grid-template-columns: repeat(2, 1fr);

    .item-half-0 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 4;
    }

    .item-half-1 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .item-half-2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .item-half-3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }

  &.container-half_column_of_halves\|half {
    grid-template-columns: repeat(2, 1fr);

    .item-half-0 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .item-half-1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .item-half-2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  &.container-half_column_of_thirds\|half {
    grid-template-columns: repeat(2, 1fr);

    .item-half-0 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    .item-half-1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    .item-half-2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    .item-half-3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 4;
    }
  }

  @include mobile(max) {
    .item {
      grid-column: span 1 !important;
      grid-row: span 1 !important;
    }

    &.container-full,
    &.container-full_carousel,
    &.container-half\|half,
    &.container-third\|two_thirds,
    &.container-two_thirds\|third,
    &.container-quarter\|three_quarters,
    &.container-three_quarters\|quarter,
    &.container-half\|quarter\|quarter,
    &.container-quarter\|half\|quarter,
    &.container-quarter\|quarter\|half,
    &.container-third\|third\|third,
    &.container-quarter\|quarter\|quarter\|quarter,
    &.container-quarter\|quarter\|quarter,
    &.container-third\|third,
    &.container-half,
    &.container-half\|half_column_of_halves,
    &.container-half\|half_column_of_thirds,
    &.container-half_column_of_halves\|half,
    &.container-half_column_of_thirds\|half {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &ContainsOnlyCmsCard {
    .item {

      @include mobile(max) {
        padding-top: 20px;
      }

      &:first-child {
        @include tablet(only) {
          padding-top: 40px;
        }

        @include desktop-small(only) {
          padding-top: 40px;
        }
      }

      @include tablet(only) {
        padding-top: 0;
      }

      @include desktop-small(only) {
        padding-top: 0;
      }
    }

    @include tablet(only) {
      .item {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
      }

      &.container-full,
      &.container-full_carousel,
      &.container-half\|half,
      &.container-third\|two_thirds,
      &.container-two_thirds\|third,
      &.container-quarter\|three_quarters,
      &.container-three_quarters\|quarter,
      &.container-half\|quarter\|quarter,
      &.container-quarter\|half\|quarter,
      &.container-quarter\|quarter\|half,
      &.container-third\|third\|third,
      &.container-quarter\|quarter\|quarter\|quarter,
      &.container-quarter\|quarter\|quarter,
      &.container-third\|third,
      &.container-half,
      &.container-half\|half_column_of_halves,
      &.container-half\|half_column_of_thirds,
      &.container-half_column_of_halves\|half,
      &.container-half_column_of_thirds\|half {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @include desktop-small(only) {
      .item {
        grid-column: span 1 !important;
        grid-row: span 1 !important;
      }

      &.container-full,
      &.container-full_carousel,
      &.container-half\|half,
      &.container-third\|two_thirds,
      &.container-two_thirds\|third,
      &.container-quarter\|three_quarters,
      &.container-three_quarters\|quarter,
      &.container-half\|quarter\|quarter,
      &.container-quarter\|half\|quarter,
      &.container-quarter\|quarter\|half,
      &.container-third\|third\|third,
      &.container-quarter\|quarter\|quarter\|quarter,
      &.container-quarter\|quarter\|quarter,
      &.container-third\|third,
      &.container-half,
      &.container-half\|half_column_of_halves,
      &.container-half\|half_column_of_thirds,
      &.container-half_column_of_halves\|half,
      &.container-half_column_of_thirds\|half {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    @include mobile(max) {
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &.container-half\|half,
      &.container-third\|two_thirds,
      &.container-two_thirds\|third,
      &.container-quarter\|three_quarters,
      &.container-three_quarters\|quarter,
      &.container-third\|third {
        grid-template-columns: repeat(2, 200px) !important;
      }

      &.container-half\|quarter\|quarter,
      &.container-quarter\|half\|quarter,
      &.container-quarter\|quarter\|half,
      &.container-third\|third\|third,
      &.container-quarter\|quarter\|quarter {
        grid-template-columns: repeat(3, 200px) !important;
      }

      &.container-quarter\|quarter\|quarter\|quarter {
        grid-template-columns: repeat(4, 200px) !important;
      }

      .item {
        overflow: initial;
        width: 200px;
      }
    }
  }

  &ContainsOnlyCatalog {
    /*.item {
      padding-top: 0;
    }*/
  }

  &FullTiles {
    @include mobile(max) {
      width: calc(100% + 16px) !important;
      max-width: calc(100% + 16px) !important;
      height: 100%;

      margin: 0;

      .item {
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &WithoutTitle {
    .item {
      padding-top: 0 !important;
    }
  }
}

.componentHeader {
  font-family: $secondary-font;
  word-break: break-word;
  hyphens: auto;
  color: $color-text-primary;

  &WithSideMargin {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}

.gapSpacing {
  &none {
    --awCmsPageGapSpacing: 0;
  }

  &small {
    --awCmsPageGapSpacing: 8px;
  }

  &normal {
    --awCmsPageGapSpacing: 16px;
  }

  &big {
    --awCmsPageGapSpacing: 24px;
  }

  &double {
    --awCmsPageGapSpacing: 32px;
  }

  @include mobile(max) {
    &40 {
      --awCmsPageGapSpacing: 16px;
    }

    &80 {
      --awCmsPageGapSpacing: 32px;
    }
  }
  @include tablet(min) {
    &40 {
      --awCmsPageGapSpacing: 24px;
    }

    &80 {
      --awCmsPageGapSpacing: 48px;
    }
  }
  @include desktop-small(min) {
    &40 {
      --awCmsPageGapSpacing: 32px;
    }

    &80 {
      --awCmsPageGapSpacing: 64px;
    }
  }
  @include desktop-medium(min) {
    &40 {
      --awCmsPageGapSpacing: 40px;
    }

    &80 {
      --awCmsPageGapSpacing: 80px;
    }
  }
}

.mobileIsCardOverflow {
  @include mobile(max) {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }
}

.rowSpacing {
  @mixin generateSides($sideConfig) {
    @each $spacing, $sideMap in $sideConfig {
      &#{$spacing} {
        @each $side, $val in $sideMap {
          &#{$side} {
            --awCmsPageRowSpacing#{$side}: #{$val};
          }
        }
      }
    }
  }

  &TypeIsNegative {
    margin-top: var(--awCmsPageRowSpacingBefore, 0);
    margin-bottom: var(--awCmsPageRowSpacingAfter, 0);
    padding-top: var(--awCmsPageRowSpacingInner, 0);
    padding-bottom: var(--awCmsPageRowSpacingInner, 0);
  }

  margin-top: 0;
  margin-bottom: 0;
  padding-top: calc(var(--awCmsPageRowSpacingBefore, 0px) + var(--awCmsPageRowSpacingInner, 0px));
  padding-bottom: calc(var(--awCmsPageRowSpacingAfter, 0px) + var(--awCmsPageRowSpacingInner, 0px));

  @include generateSides((
      "none": (
          "Inner": "0px",
          "Before": "0px",
          "After": "0px",
      ),
      "small": (
          "Inner": "8px",
          "Before": "8px",
          "After": "8px",
      ),
      "normal": (
          "Inner": "16px",
          "Before": "16px",
          "After": "16px",
      ),
      "big": (
          "Inner": "24px",
          "Before": "24px",
          "After": "24px",
      ),
      "double": (
          "Inner": "32px",
          "Before": "32px",
          "After": "32px",
      ),
  ));
  @include mobile(max) {
    @include generateSides((
        "40": (
            "Inner": "20px",
            "Before": "20px",
            "After": "20px",
        ),
        "80": (
            "Inner": "40px",
            "Before": "40px",
            "After": "40px",
        ),
    ));
  }
  @include tablet(min) {
    @include generateSides((
        "40": (
            "Inner": "40px",
            "Before": "40px",
            "After": "40px",
        ),
        "80": (
            "Inner": "80px",
            "Before": "80px",
            "After": "80px",
        ),
    ));
  }
}
</style>
