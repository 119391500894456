<template>
  <aw-carousel-v3-image-only v-if="isSimplified" :content="carouselV3ImageOnlyContent" :analytics-banner-interaction="EMPTY_ANALYTICS_BANNER_INTERACTION" />
  <div v-else :class="[$style.preview]" :style="backgroundStyle">
    <div :class="$style.previewContent">
      <h1 :class="$style.previewContentTitle">
        {{ content.title }}
      </h1>
      <span :class="$style.previewContentText" v-html="content.text" />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { EMPTY_ANALYTICS_BANNER_INTERACTION } from '~~/common/utils/AnalyticsBannerInteraction';

  export default {
    name: 'AwCmsPreview',
    components: { AwCarouselV3ImageOnly: defineAsyncComponent(() => import('~~/common/components/AwStandardPartials/AwCarouselV3/AwCarouselV3ImageOnly.vue')) },
    props: {
      content: {
        type: Object,
        required: true,
      },
      // Simplified version has no black overlay nor title and description,
      // and background position is centered
      isSimplified: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        EMPTY_ANALYTICS_BANNER_INTERACTION,
      };
    },
    computed: {
      backgroundStyle () {
        return {
          backgroundImage: this.isSimplified ? `url(${this.content.image.url})` : `linear-gradient(to bottom, rgba(0,0,0,.32), rgba(0,0,0,.32)), url(${this.content.image.url})`,
        };
      },
      carouselV3ImageOnlyContent () {
        return {
          components: [
            {
              media: {
                contentImage: this.content.image,
              },
            },
          ],
        };
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.preview {
  position: relative;
  overflow: hidden;
  min-height: 92px;
  border-radius: 24px;
  background-size: cover;

  @include tablet(min) {
    min-height: 302px;
  }


  &Content {
    font-family: $secondary-font;
    width: 100%;
    max-width: 512px;
    height: 100%;
    padding: 40px 16px;
    color: $color-white;

    @include tablet(min) {
      padding: 64px 48px;
    }

    &Title {
      font-size: $heading-1-font-size-mobile-version2;
      font-weight: $font-weight-bold-v2;
      line-height: $heading-1-line-height-mobile-version2;
      margin-bottom: 20px;
      @include tablet(min) {
        font-size: $heading-1-font-size-desktop-version2;
        line-height: $heading-1-line-height-desktop-version2;
      }
    }

    &Text {
      font-size: $heading-4-font-size-mobile-version2;
      font-weight: $font-weight-bold-v2;
      line-height: $heading-4-line-height-mobile-version2;
      @include tablet(min) {
        font-size: $heading-4-font-size-desktop-version2;
        line-height: $heading-4-line-height-desktop-version2;
      }
    }
  }
}
</style>
